<template>
  <b-container class="account-wizard bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col>
        <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
        <h1 class="text-left mb-0" v-if="!isUpdate">
          {{ typeOfAccountGroup ? $t( translationPath + (currentUser.strict_accounting_mode ? 'create_account_group_' : 'create_category_group_') + typeOfAccountGroup) : '' }} {{ typeOfAccountGroup ? h1AccountGroupTitle : '' }}
        </h1>
        <h1 class="text-left mb-0" v-if="isUpdate">
          {{ typeOfAccountGroup ? $t( translationPath + (currentUser.strict_accounting_mode ? 'edit_account_group_' : 'edit_category_group_') + typeOfAccountGroup ) : '' }} {{ typeOfAccountGroup ? h1AccountGroupTitle: '' }}
        </h1>
        <div class="step-container-v2">
          <div class="stepper bg-light mx-auto text-regular">
            <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="step">
              <tab-content :title="$t( this.translationPath + 'step1_title')" :before-change="()=>validateStep('step1')">
                <account-group-wizard-step01 ref="step1" @account-type-changed="onAccountTypeChanged" @on-validate="mergePartialModels" :initialData="editAccountGroupDetails" :isUpdate="isUpdate" :typeOfAccountGroup="typeOfAccountGroup"></account-group-wizard-step01>
              </tab-content>
              <tab-content :title="$t( this.translationPath + 'step2_title')" :before-change="()=>validateStep('step2')">
                <account-group-wizard-step02 ref="step2" @on-validate="mergePartialModels" :initialData="editAccountGroupDetails" :isUpdate="isUpdate" :typeOfAccountGroup="finalModel.account_group_account_type"></account-group-wizard-step02>
              </tab-content>
              <tab-content :title="$t( this.translationPath + 'step3_title')" :before-change="()=>validateStep('step3')">
                <account-group-wizard-step03 ref="step3" @on-validate="mergePartialModels" :initialData="editAccountGroupDetails" :isUpdate="isUpdate" :type="typeOfAccountGroup" :finalModel="finalModel" :suggestedCode="suggestedCode"></account-group-wizard-step03>
              </tab-content>
              <template slot="footer" slot-scope="props">
                <div :class="{ hasSpace: props.activeTabIndex !== 0 }">
                  <div class=wizard-footer-left>
                    <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle" tabindex="0">
                      {{ $t('common.previous') }}
                    </wizard-button>
                  </div>
                  <div class="line" v-if="props.activeTabIndex !== 0"></div>
                  <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="props.activeTabIndex === 1 && nextDisabled" tabindex="0">
                      {{ $t('common.next') }}
                    </wizard-button>
                    <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" tabindex="0">
                      {{ $t('common.save') }}
                    </wizard-button>
                  </div>
                </div>
              </template>
            </form-wizard>

            <success-modal v-if="!isUpdate"
              :title="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'title_account' : 'title_category'),{account_group: finalModel.account_group_title})"
              :message="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'message_account' : 'message_category'),{account_group: finalModel.account_group_title})"
              :redirect="'/reports/other/account-plan-v2/' + ( typeOfAccountGroup === 'liability' ? 'liabilities' : typeOfAccountGroup + 's')"
              :orLink="(['income', 'expense'].indexOf(typeOfAccountGroup) > -1 ? '/redirect?to=accountsGroupsCreate&accountType=' : '/redirect?to=accountsGroupsCreateAssets&accountType=') + typeOfAccountGroup"
              :button="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'button_account_overview' : 'button_category_overview'),{account_group: finalModel.account_group_title})"
              :orLinkText="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'or_create_account_group' : 'or_create_category_group'))"
              @hide="$router.push((['income', 'expense'].indexOf(typeOfAccountGroup) > -1 ? '/redirect?to=accountsGroupsCreate&accountType=' : '/redirect?to=accountsGroupsCreateAssets&accountType=') + typeOfAccountGroup)"
            ></success-modal>
            <success-modal v-if="isUpdate"
              :title="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'title_account_update' : 'title_category_update'),{account_group: finalModel.account_group_title})"
              :message="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'message_account_update' : 'message_category_update'),{account_group: finalModel.account_group_title})"
              :redirect="'/reports/other/account-plan-v2/' + ( typeOfAccountGroup === 'liability' ? 'liabilities' : typeOfAccountGroup + 's')"
              :button="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'button_account_overview_update' : 'button_category_overview_update'),{account_group: finalModel.account_group_title})"
              :orLink="(['income', 'expense'].indexOf(typeOfAccountGroup) > -1 ? '/redirect?to=accountsGroupsCreate&accountType=' : '/redirect?to=accountsGroupsCreateAssets&accountType=') + typeOfAccountGroup"
              :orLinkText="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'or_create_account_group' : 'or_create_category_group'))"
              @hide="$router.push((['income', 'expense'].indexOf(typeOfAccountGroup) > -1 ? '/redirect?to=accountsGroupsCreate&accountType=' : '/redirect?to=accountsGroupsCreateAssets&accountType=') + typeOfAccountGroup)"
            ></success-modal>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AccountGroupWizardStep01 from './wizard-steps/AccountGroupWizardStep01'
import AccountGroupWizardStep02 from './wizard-steps/AccountGroupWizardStep02'
import AccountGroupWizardStep03 from './wizard-steps/AccountGroupWizardStep03'
import SuccessModal from '@/components/modals/SuccessModal'
import titleMixins from '@/mixins/title'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import axios from 'axios'
import { mapState } from 'vuex'
import EventBus from '@/event-bus.js'

export default {
  name: 'wizardAccountGroup',
  mixins: [titleMixins],
  data () {
    return {
      accountGroupId: null,
      nextDisabled: false,
      finalModel: {},
      isCompleted: false,
      h1AccountGroupTitle: null,
      editAccountGroupDetails: {
        id: null,
        code: null
      },
      suggestedCode: null,
      translationPath: 'incomes_expenses.accounts.create_account_group_wizard.common.',
      translationPathModal: 'incomes_expenses.accounts.create_account_group_wizard.success_modal.',
      isStep3Ok: null,
      isUpdate: false,
      typeOfAccountGroup: null
    }
  },
  components: {
    SuccessModal,
    FormWizard,
    TabContent,
    WizardButton,
    AccountGroupWizardStep01,
    AccountGroupWizardStep02,
    AccountGroupWizardStep03
  },
  created () {
    /* EventBus.$on('accounts_selected', status => {
      this.nextDisabled = !status
    }) */
    if (this.$route.name === 'incomes-expenses-groups-edit' || this.$route.name === 'assets-liabilities-groups-edit') {
      const accountGroupId = (Object.keys(this.$route.params).length > 0) ? (Object.prototype.hasOwnProperty.call(this.$route.params, 'account_group_id') ? this.$route.params.account_group_id : null) : null
      this.isUpdate = true
      this.getAccountGroupDetails(accountGroupId)
        .then(responseData => responseData.data.data)
        .then(data => {
          this.editAccountGroupDetails = data
          console.log('data :::: ', data)
          this.accountGroupId = accountGroupId
          this.typeOfAccountGroup = data.type
          Object.assign(this.finalModel, { account_group_title: data.title })
          Object.assign(this.finalModel, { account_group_account_type: data.type })
          this.mergePartialModels({}) // Calls finalModel watch
        })
        .catch(err => {
          const { response } = err
          if (response && response.status === 404) {
            this.$router.push({
              name: '404'
            })
          }
        })
    } else {
      this.typeOfAccountGroup = this.$route.params.account_group_type
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    breadcrumbItems () {
      const breadCrumbs = [
        { text: ['income', 'expense'].indexOf(this.typeOfAccountGroup) > -1 ? this.$t('common.header.header_menu.manage_title') : this.$t('common.header.header_menu.manage_title'), to: ['income', 'expense'].indexOf(this.$route.params.account_group_type) > -1 ? '/other/account-plan-v2/incomes' : '/other/account-plan-v2/assets' }
      ]
      if (['income', 'expense'].indexOf(this.typeOfAccountGroup) > -1) {
        breadCrumbs.push({ text: (this.typeOfAccountGroup ? this.$t(this.translationPath + ((this.currentUser.strict_accounting_mode ? 'account_groups_' : 'category_groups_') + this.typeOfAccountGroup)) : ''), to: '/incomes-expenses/accounts/' + this.typeOfAccountGroup })
        breadCrumbs.push({ text: '', active: true })
      } else {
        breadCrumbs.push({ text: (this.typeOfAccountGroup ? this.$t(this.translationPath + ((this.currentUser.strict_accounting_mode ? 'account_groups_' : 'category_groups_') + this.typeOfAccountGroup)) : ''), to: '/reports/other/account-plan-v2/assets' })
        breadCrumbs.push({ text: '', active: true })
      }
      return breadCrumbs
    },
    titleMeta () {
      if (!this.isUpdate) {
        return this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'create_account_group_' : 'create_category_group_') + this.typeOfAccountGroup)
      }
      return this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'edit_account_group_' : 'edit_category_group_') + this.typeOfAccountGroup)
    }
  },
  methods: {
    validateStep (name) {
      return this.$refs[name].validate()
    },
    async onComplete () {
      var self = this

      await this.validateStep('step3')
        .then(function (response) {
          self.isStep3Ok = response
        })
        .catch(function (error) { console.error(error) })

      if (self.isStep3Ok) {
        if (this.finalModel.account_group_deselected_accounts.length) {
          const data = { accounts: [] }
          this.finalModel.account_group_deselected_accounts.forEach((item) => {
            data.accounts.push({
              id: item.id,
              group_id: item.group_id
            })
          })

          await axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/update-multiple`, data)
            .catch((error) => {
              console.error(error)
            })
        }

        const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/accounts/groups' + (this.isUpdate ? '/' + this.accountGroupId : '')
        const sApiMethod = this.isUpdate ? 'put' : 'post'
        await axios({
          method: sApiMethod,
          url: sApiEndpoint,
          data: this.getWizardData()
        })
          .then(function (response) {
            self.accountGroupId = response.data.data.id
          })
          .catch(function (error) {
            if (error.response.status === 422 && typeof (error.response.data.error.data.code) !== 'undefined') {
              self.$refs.step3.uniqueCode(false)
            }
            if (error.response.status === 422 && typeof (error.response.data.error.data.title) !== 'undefined') {
              EventBus.$emit('make-toast', {
                text: 'Title has already been taken. Please go back to step 1 and redo the wizard.',
                status: 'danger'
              })
            }
            console.error(error)
          })

        if (this.finalModel.account_group_selected_accounts.length) {
          const data = { accounts: [] }
          this.finalModel.account_group_selected_accounts.forEach((item) => {
            data.accounts.push({
              id: item.id,
              group_id: self.accountGroupId
            })
          })

          await axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/update-multiple`, data)
            .then((response) => {
              self.$bvModal.show('successModal')
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          self.$bvModal.show('successModal')
        }
      }
    },
    mergePartialModels (model) {
      // merging each step model into the final model
      this.finalModel = Object.assign({}, this.finalModel, model)
    },
    onAccountTypeChanged (newType) {
      if (this.accountGroupId) {
        // Suggest code only for new groups
        return true
      }

      axios.get(`${process.env.VUE_APP_ROOT_API}/accountant/suggest-account-group-code/${newType}`)
        .then((response) => {
          this.suggestedCode = response.data.data.suggested_code
        })
        .catch((error) => {
          console.error(error)
        })
    },
    getWizardData () {
      const accounts = []
      for (var prop in this.finalModel.account_group_selected_accounts) {
        accounts.push({ id: this.finalModel.account_group_selected_accounts[prop].id })
      }

      const wizardData = {
        title: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_group_title') && this.finalModel.account_group_title ? this.finalModel.account_group_title : null,
        type: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_group_account_type') && this.finalModel.account_group_account_type ? this.finalModel.account_group_account_type : null,
        // 'accounts': accounts,
        description: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_group_description') && this.finalModel.account_group_description ? this.finalModel.account_group_description : null
      }
      const code = Object.prototype.hasOwnProperty.call(this.finalModel, 'account_code') && this.finalModel.account_code ? this.finalModel.account_code : null
      if (code !== null) {
        wizardData.code = code
      }

      return wizardData
    },
    async getAccountGroupDetails (accountGroupId) {
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/${accountGroupId}`)
    }
  },
  watch: {
    finalModel () {
      this.h1AccountGroupTitle = ': "' + this.finalModel.account_group_title + '"'
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/form-wizard-2.scss';
  @import '@/assets/scss/modal.scss';
  @import '@/assets/scss/components/spirecta-table-where-row-is-selected.scss';
  .create_account_group-wizard {
    .accounts_wrapper {
      border-left: 1px solid #D7D7D7;
      @media screen and (max-width:767.98px) {
        border-left:none;
      }
    }
  }
</style>
