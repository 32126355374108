<template>
  <b-container class="create_account_group-wizard create_account_group-wizard-step03">
    <b-row no-gutters>
      <b-col cols="12" md="5" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5">
        <h2 class="text-dark">
          {{ $t( translation_path + ( currentUser.strict_accounting_mode ? 'title_account_group' : 'title_category_group') ) }}
        </h2>
        <i18n class="text-gray mb-0" :path="translation_path + ( currentUser.strict_accounting_mode ? 'intro_p1_account_group' : 'intro_p1_category_group' )" tag="p">

        </i18n>
      </b-col>
      <b-col cols="12" md="7" class="accounts_wrapper pb-3 pt-0 px-3 py-md-4 px-md-4 py-lg-5 px-lg-5">

        <template v-if="Object.prototype.hasOwnProperty.call(finalModel, 'account_group_deselected_accounts') && finalModel.account_group_deselected_accounts.length">
          <p>{{ $t(translation_path + (currentUser.strict_accounting_mode ? 'deselected_accounts_title' : 'deselected_categories_title')) }}</p>
          <b-table hover responsive striped
            :items="finalModel.account_group_deselected_accounts"
            :fields="fieldValues"
            stacked="md"
            class="accounts-table-group depth-1 spirecta-simple-table" show-empty
          >
            <template v-slot:cell(actions)="row">
              <b-form-select
                v-model="row.item.group_id"
                :options="accountGroupOptions(row.item.type)"
                class="rounded-pill"
              ></b-form-select>
            </template>
          </b-table>
        </template>

        <!--Advanced Toggle-->
        <b-form-group
          :label-class="'pr-4 text-black pt-1'"
          label-cols-lg="4"
          label-align-lg="right"
          :label="$t(translation_path + 'toggle_advanced')"
        >
          <b-form-checkbox v-model="showOptions" name="toggle-button" class='' size="lg" switch>
          </b-form-checkbox>
        </b-form-group>

        <template v-if="showOptions === true">
          <h4>{{ $t(translation_path + 'optional_settings') }}</h4>
          <p>{{ $t(translation_path + 'optional_description') }}</p>

          <b-form-group
            label-class="pr-4 text-black"
            label-for="account_code"
            label-cols-lg="4"
            label-align-lg="right"
            :invalid-feedback="invalidAccountCodeFeedback"
            :state="!$v.account_code.$error"
          >

            <template slot="label">
              <i18n :path="translation_path + 'code_label'">
              </i18n>
            </template>
            <template slot="description">{{ $t(translation_path + 'code_help_text') }} {{ $t(translation_path + 'code_help_text_recommendation_' + $route.params.account_group_type ) }}</template>
            <b-form-input
              :placeholder="$t(translation_path + 'code_placeholder')"
              :state="$v.account_code.$dirty ? !$v.account_code.$error : null"
              v-model="account_code"
              @input="$v.account_code.$touch()"
              class="rounded-pill"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-class="pr-4 text-black"
            label-for="account_group_description"
            label-cols-lg="4"
            label-align-lg="right"
          >
            <template slot="label">{{ $t(translation_path + 'description_label') }}</template>
            <b-form-textarea id="account_group_description" v-model="account_group_description" rows="8"></b-form-textarea>
          </b-form-group>
        </template>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { helpers } from 'vuelidate/lib/validators'

const customAccountCodeRule = helpers.regex('customAccountCodeRule', /^\d{4,6}$/)
const isCodeUnique = getter => function () {
  return this.is_code_unique
}
export default {
  name: 'AccounGrouptWizardStep03',
  props: ['initialData', 'finalModel', 'suggestedCode', 'isUpdate', 'type'],
  data () {
    return {
      account_group_description: null,
      account_code: null,
      showOptions: false,
      is_code_unique: true,
      account_groups: [],
      translation_path: 'incomes_expenses.accounts.create_account_group_wizard.step3.'
    }
  },
  validations: {
    account_code: {
      customAccountCodeRule,
      codeUnique: isCodeUnique()
    },
    form: ['account_code']
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    invalidAccountCodeFeedback () {
      if (this.$v.account_code.customAccountCodeRule === false) { return this.$i18n.t(this.translation_path + 'errors.code_invalid') }
      if (this.$v.account_code.codeUnique === false) { return this.$i18n.t(this.translation_path + 'errors.code_already_taken') }
      return ''
    },
    fieldValues () {
      return [
        { key: 'title', label: 'Title' },
        { key: 'actions', label: '' }
      ]
    }
  },
  methods: {
    uniqueCode (status) {
      this.is_code_unique = status
    },
    fetchAccountGroups () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups?account_type=income,expense,asset,liability&limit=1000`)
        .then((response) => {
          this.account_groups = response.data.data
        })
        .catch((error) => {
          console.error(error)
        })
      this.account_groups = []
    },
    accountGroupOptions (type) {
      const filtered = this.account_groups.filter((item) => {
        if (item.type === this.finalModel.account_group_account_type) {
          return item
        }
      })

      const options = []
      filtered.forEach((item) => {
        const option = {
          value: item.id,
          text: item.title
        }
        options.push(option)
      })

      return options
    },
    async validate () {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (isValid) {
        const data = {
          account_group_description: this.account_group_description,
          account_code: this.account_code,
          account_group_deselected_accounts: this.finalModel.account_group_deselected_accounts
        }
        this.$emit('on-validate', data)
      }
      return isValid
    }
  },
  watch: {
    initialData: {
      handler: function (val) {
        if (Object.keys(val).length > 0) {
          this.account_group_description = Object.prototype.hasOwnProperty.call(val, 'description') && val.description ? val.description : null
          this.account_code = Object.prototype.hasOwnProperty.call(val, 'code') && val.code ? val.code : null
        }
      },
      immediate: true,
      deep: true
    },
    suggestedCode () {
      this.account_code = this.suggestedCode
    }
  },
  created () {
    this.fetchAccountGroups()
  }
}
</script>

<style lang="scss">
.create_account_group-wizard-step03{
  .input-group-text{
    background: white!important;
    margin-left:0;
  }
  h4{margin-top:25px;padding-top:25px;border-top:1px dashed gray}
}
</style>
