<template>
  <b-container class="create_account_group-wizard create_account_group-wizard-step01">
    <b-row no-gutters>
      <b-col cols="12" md="5" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5">
        <h2 class="text-dark">
          {{ $t( translation_path + ( currentUser.strict_accounting_mode ? 'title_account_group' : 'title_category_group') ) }}
        </h2>
        <p class="">
          {{ $t( translation_path + ( currentUser.strict_accounting_mode ? 'intro_p1_account_group' : 'intro_p1_category_group' )) }}
        </p>
      </b-col>
      <b-col cols="12" md="7" class="accounts_wrapper pb-3 pt-0 px-3 py-md-4 px-md-4 py-lg-5 px-lg-5">

        <b-form-group
          label-class="pr-4 text-black"
          label-for="account_group_title"
          label-cols-lg="3"
          label-align-lg="right"
          required
          class="pt-md-4"
          :invalid-feedback="invalidAccountGroupTitleFeedback"
          >

          <template slot="label">{{ $t(translation_path + 'title_label') }}</template>
          <template slot="description" v-if="typeOfAccountGroup">{{ $t(translation_path + 'title_help_text_'+typeOfAccountGroup) }}</template>
          <b-form-input id="account_group_title" class="rounded-pill"
                        :placeholder="$t(translation_path+'title_placeholder')"
                        :state="$v.account_group_title.$dirty ? !$v.account_group_title.$error : null"
                        v-model="account_group_title"
                        @input="$v.account_group_title.$touch()"
          ></b-form-input>
        </b-form-group>
        <!--<b-form-group
          v-if="!isUpdate"
          label-for="account_group_account_type"
          label-cols-lg="3"
          label-align-lg="right"
          required
          label-class="pr-4 text-black"
          :invalid-feedback="invalidAccountGroupTypeFeedback"
        >
          <template slot="label">{{ $t(translation_path + (currentUser.strict_accounting_mode ? 'type_account_label' : 'type_category_label')) }}</template>
          <b-form-select id="account_group_account_type" class="rounded-pill"
            v-model="account_group_account_type"
            :state="$v.account_group_account_type.$dirty ? !$v.account_group_account_type.$error : null"
            :options="account_types"
          ></b-form-select>
        </b-form-group>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import axios from 'axios'
const isTitleUnique = getter => function () {
  return this.is_title_unique
}

export default {
  name: 'AccountGroupWizardStep01',
  props: ['initialData', 'isUpdate', 'typeOfAccountGroup'],
  data () {
    return {
      account_group_title: null,
      account_group_account_type: null,
      translation_path: 'incomes_expenses.accounts.create_account_group_wizard.step1.',
      is_title_unique: true
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    invalidAccountGroupTitleFeedback () {
      if (this.$v.account_group_title.required === false) { return this.$i18n.t(this.translation_path + 'errors.title_required') }
      if (this.$v.account_group_title.minLength === false) { return this.$i18n.t(this.translation_path + 'errors.title_minlength') }
      if (this.$v.account_group_title.titleUnique === false) { return this.$i18n.t(this.translation_path + 'errors.title_already_taken') }
      return ''
    },
    invalidAccountGroupTypeFeedback () {
      if (this.$v.account_group_account_type.required === false) { return this.$i18n.t(this.translation_path + (this.currentUser.strict_accounting_mode ? 'errors.account_type_required' : 'errors.category_type_required')) }
      return ''
    },
    account_types () {
      return [
        { value: null, text: '' },
        { value: 'income', text: this.$t('common.income') },
        { value: 'expense', text: this.$t('common.expense') },
        { value: 'asset', text: this.$t('common.asset') },
        { value: 'liability', text: this.$t('common.liability') }
      ]
    }
  },
  created () {
    this.account_types[0].text = this.$t(this.translation_path + (this.currentUser.strict_accounting_mode ? 'type_account_placeholder' : 'type_category_placeholder'))
    this.account_group_account_type = this.typeOfAccountGroup
  },
  validations: {
    account_group_title: {
      required,
      minLength: minLength(2),
      titleUnique: isTitleUnique()
    },
    /* account_group_account_type: {
      required
    }, */
    // form: ['account_group_title', 'account_group_account_type']
    form: ['account_group_title']
  },
  methods: {
    async validate () {
      this.$v.form.$touch()
      const self = this
      let isValid = !this.$v.form.$invalid

      const postData = {
        title: this.account_group_title,
        type: this.account_group_account_type,
        only_validate: true
      }

      // Validate that title is unique on create
      if (isValid) {
        const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/accounts/groups' + (this.isUpdate ? '/' + this.initialData.id : '')
        const sApiMethod = this.isUpdate ? 'put' : 'post'

        await axios({
          method: sApiMethod,
          url: sApiEndpoint,
          data: postData
        })
          .catch(function (error) {
            if (error.response.status === 422) {
              isValid = false
              self.is_title_unique = false
            }
          })
      }

      if (isValid) {
        const data = {
          account_group_title: this.account_group_title,
          account_group_account_type: this.account_group_account_type
        }
        this.$emit('on-validate', data)
      }

      return isValid
    }
  },
  watch: {
    initialData: {
      handler: function (val) {
        if (Object.keys(val).length > 0) {
          this.account_group_title = Object.prototype.hasOwnProperty.call(val, 'title') && val.title ? val.title : null
          this.account_group_account_type = Object.prototype.hasOwnProperty.call(val, 'type') && val.type ? val.type : null
        }
      },
      immediate: true,
      deep: true
    },
    account_group_title () {
      this.is_title_unique = true
    },
    account_group_account_type () {
      this.$emit('account-type-changed', this.account_group_account_type)
    }
  }
}
</script>

<style lang="scss">
.input-group-text{
  background: white!important;
  margin-left:0;
}
#account_percentage{
  padding-right: 2px;
  text-align: right;
}
</style>
