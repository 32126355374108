<template>
  <b-container class="create_account_group-wizard create_account_group-wizard-step02">
    <b-row no-gutters>
      <b-col cols="12" md="5" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5">
        <h2 class="text-dark">
          {{ $t( translationPath +(currentUser.strict_accounting_mode ? 'title_account_group' : 'title_category_group' ) ) }}
        </h2>
        <p>
          {{ $t( translationPath + ( currentUser.strict_accounting_mode ? 'intro_p1_account_group' : 'intro_p1_category_group' )) }}
        </p>
        <p>
          {{ $t( translationPath + ( currentUser.strict_accounting_mode ? 'intro_p2_account_group' : 'intro_p2_category_group' )) }}
        </p>
      </b-col>
      <b-col cols="12" md="7" class="accounts_wrapper pb-3 pt-0 px-3 py-md-4 px-md-4 py-lg-5 px-lg-5">
        <b-table hover responsive striped
          :items="account_group_accounts"
          :fields="fieldValues"
          :filter="title_filter"
          ref="accounts_table"
          stacked="md"
          class="accounts-table-group spirecta-simple-table" show-empty
          :busy="busyState"
          @row-clicked="onRowClicked"
        >
          <!--loader-->
          <template v-slot:table-busy>
            <loader/>
          </template>
          <!--loader ends here-->
          <template slot="top-row">
              <td colspan="5">
                <div class="d-flex align-items-center">
                  <i class="fa fa-search"></i>
                  <b-form-input v-model="title_filter" size="sm" :placeholder="$t( translationPath + ( currentUser.strict_accounting_mode ? 'account_title_filter_placeholder' : 'category_title_filter_placeholder'))" />
                </div>
              </td>
            </template>
          <template slot="empty">
            <h4 class="font-weight-light mb-0">{{$t(translationPath + 'no_accounts')}}</h4>
          </template>

          <template v-slot:head(is_selected)>
            <b-form-checkbox
              :id="'checkbox_account_all'"
              :value="true"
              :unchecked-value="false"
              v-model="isAllSelected"
              @change="toggleIsAllSelected"
            ></b-form-checkbox>
          </template>
          <template v-slot:cell(is_selected)="row">
            <b-form-checkbox
              :id="'checkbox_account_' + row.item.id"
              :value="true"
              :unchecked-value="false"
              @input="onAccountSelected(row.item)"
              v-model="row.item.is_selected"
            ></b-form-checkbox>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
// import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import Loader from '@/components/common/Loader'
// import EventBus from '@/event-bus'

export default {
  name: 'AccounGrouptWizardStep02',
  props: ['initialData', 'typeOfAccountGroup', 'isUpdate'],
  components: { Loader },
  data () {
    return {
      account_group_accounts: [],
      translationPath: 'incomes_expenses.accounts.create_account_group_wizard.step2.',
      title_filter: null,
      busyState: false,
      isAllSelected: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      return [
        { key: 'is_selected', label: '', sortable: false },
        { key: 'title', label: this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'title_accounts' : 'title_categories') + '_' + (this.typeOfAccountGroup ? this.typeOfAccountGroup : 'income')), sortable: true },
        { key: 'account_group.title', label: this.$t(this.translationPath + 'current_group'), sortable: true }
      ]
    },
    invalidAccountGroupSelectedAccountsFeedback () {
      if (this.$v.account_group_selected_accounts.required === false) {
        return this.$i18n.t(this.translationPath + 'errors.accounts_required')
      }
      return ''
    }
  },
  validations: {
    /* account_group_selected_accounts: {
      required
    }, */
    form: ['account_group_selected_accounts']
  },
  methods: {
    async validate () {
      this.$v.form.$touch()
      // let isValid = !this.$v.form.$invalid
      const isValid = true

      if (isValid) {
        const data = {
          account_group_selected_accounts: this.account_group_accounts.filter((item) => {
            if (item.is_selected) {
              return item
            }
          }),
          account_group_deselected_accounts: []
        }

        if (Object.prototype.hasOwnProperty.call(this.$route.params, 'account_group_id')) {
          data.account_group_deselected_accounts = this.account_group_accounts.filter((item) => {
            if (!item.is_selected && item.group_id === parseInt(this.$route.params.account_group_id)) {
              return item
            }
          })
        }

        this.$emit('on-validate', data)
      }
      return isValid
    },
    async getAccountsForAccountGroup () {
      this.busyState = true
      try {
        const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?account_type=` + this.typeOfAccountGroup)
        this.account_group_accounts = response.data.data.map((item) => {
          if (item.group_id === this.initialData.id) {
            item.is_selected = true
          } else {
            item.is_selected = false
          }
          return item
        })

        this.busyState = false
      } catch (error) {
        console.error(error)
        this.busyState = false
      }
    },
    toggleIsAllSelected (val) {
      this.account_group_accounts = this.account_group_accounts.map((item) => {
        item.is_selected = val
        return item
      })
    },
    onAccountSelected (item) {
      let isAllSelected = true
      for (const i in this.account_group_accounts) {
        if (!this.account_group_accounts[i].is_selected) {
          isAllSelected = false
          break
        }
      }
      this.isAllSelected = isAllSelected
    },
    onRowClicked (item) {
      item.is_selected = !item.is_selected
    }
  },
  created () {
    this.getAccountsForAccountGroup()
  },
  watch: {
    initialData: {
      handler: function (val) {
        if (Object.keys(val).length > 0) {
          this.account_group_accounts = Object.prototype.hasOwnProperty.call(val, 'accounts') && val.accounts ? val.accounts : null
        }
      },
      immediate: true,
      deep: true
    },
    typeOfAccountGroup () {
      this.getAccountsForAccountGroup()
    }
  }
}
</script>

<style lang="scss">
.input-group-text{
  background: white!important;
  margin-left:0;
}
.accounts-table-group
  {
    thead {
      tr {
        th {
          background: white;
          padding:15px 0px 15px 10px;
          font-size: 125%;
          &:nth-child(1) {
            &[aria-sort] {
              &:not(.b-table-sort-icon-left) {
              background-position:right calc(27.75rem / 2) center;
             }
            }
          }
          &:nth-child(2) {
            &[aria-sort] {
              &:not(.b-table-sort-icon-left) {
              background-position:right calc(6.75rem / 2) center;
             }
            }
          }
        }
        h2{
          margin:0;
        }
      }
    }
    tbody{
      tr:hover td{
        background-color: #fafbfc;
      }
      td {
        padding: 10px 10px;
        border-top:1px dashed #CACACA;
        vertical-align: middle;
      }
      tr:nth-child(odd){
        background: #fefeff;
      }
      tr:nth-child(even){
        background: #fefeff;
      }
      td:nth-child(1){
        padding-left:20px;
      }
      td:nth-child(1){
        font-weight:700;
      }
      button {
        &.active{
          border: 2px solid #0065ff;
        }
      }
    }
    .fa-search {
      margin-right: 15px;
    }
  }
</style>
